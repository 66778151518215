<template>
  <div class="edit-promotion">
    <promotion-form />
  </div>
</template>

<script>
import PromotionForm from "@/components/promotion-form";

export default {
  name: "edit-promotion",
  components: {
    PromotionForm
  }
};
</script>

<style></style>
